
function Divider(){
    return (
        <body>

        <div className="spacer layer1"></div>

        </body>
    )
}

export default Divider;