
function Navbar() {
    return (
        <body className="navbar">
        <header className='header'>
        <svg xmlns="http://www.w3.org/2000/svg"  height='28px' width='28px' viewBox="0 0 640 512"><path  fill="#FFFFFF" d="M256.5 216.8l86.7 109.2s-16.6 102.4-76.6 150.1C206.7 523.9 0 510.2 0 510.2s3.8-23.1 11-55.4l94.6-112.2c4-4.7-.9-11.6-6.7-9.5l-60.4 22.1c14.4-41.7 32.7-80 54.6-97.5 60-47.8 163.3-40.9 163.3-40.9zM636.5 31l-19.9-25c-5.5-6.9-15.5-8.1-22.4-2.6l-232.5 177.8-34.1-43c-5.1-6.4-15.1-5.2-18.6 2.2l-25.3 54.6 86.7 109.2 58.8-12.5c8-1.7 11.4-11.2 6.3-17.6l-34.1-42.9 232.5-177.8c6.9-5.5 8-15.5 2.6-22.4z"/></svg>
        <a className='logo'> Open Air Services</a>
    </header>
    </body>
    )
}
export default Navbar;