import './App.css'
// import { Tilt } from "react-tilt";
import React, { useEffect, useState, useRef } from 'react';

import { motion } from "framer-motion";
import img from './The-Monocot-Studio-Evelyn-Residences-9c.png'

import emailjs from '@emailjs/browser'



function App() {

  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: '', // Clear error on change
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.name) newErrors.name = 'Name is required';
    if (!form.email) newErrors.email = 'Email is required';
    if (!form.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);

    emailjs
      .send(
        'service_eo1d3eg',
        'template_6p32wdr',
        {
          from_name: form.name,
          to_name: "OpenAirServices",
          from_email: form.email,
          to_email: "qsftyhko@gmail.com",
          message: form.message,
        },
        'DDSC7nnv8ziFOP5C9'
      )
      .then(
        () => {
          setLoading(false);
          alert("Thank you. I will get back to you as soon as possible.");

          setForm({
            name: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          setLoading(false);
          console.error(error);

          alert("Ahh, something went wrong. Please try again.");
        }
      );
  };

  return (
   
    <body >
      
      <a className='questions'> <svg className='phone' xmlns="http://www.w3.org/2000/svg" height='25px' width='25px' viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
      Questions? Call us at 647-780-5842</a>
   
    
    
      <motion.div
    initial={{ opacity: 0, scale: 0.3 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ type: "spring", stiffness: 100 }}
    >
      <div className="topImage">
        <img src={img} alt="Italian Trulli" />
        

    </div> 
    </motion.div>
    <div className='form-padding'>
  <section className='container'>
    <form action="#" className="form" ref={formRef} onSubmit={handleSubmit}>
      <header className='header2'>Get An Estimate! We clean houses at an affordable rate</header>

      <div className="input-box">
        <label>Name</label>
        <input 
          name='name'
          value={form.name}
          onChange={handleChange}
          placeholder='Name'
        />
        {errors.name && <span className='error'>{errors.name}</span>}
      </div>
      <div className="input-box">
        <label>Email</label>
        <input
          name='email'
          value={form.email}
          onChange={handleChange}
          placeholder='Email'
        />
        {errors.email && <span className='error'>{errors.email}</span>}
      </div>
      <div className="input-box">
        <label>Location</label>
        <input 
          name='location'
          value={form.location}
          onChange={handleChange}
          placeholder='Location'
        />
      </div>
      <div className="input-box">
        <label>Message</label>
        <textarea 
          name='message'
          value={form.message}
          onChange={handleChange}
          className='message'
          placeholder='Message'
        />
        {errors.message && <span className='error'>{errors.message}</span>}
      </div>
      <button type='submit' className='button-4'>
        {loading ? "Sending..." : "Send"}
      </button>
    </form>
  </section> 
</div>


    <div className="estimate4">

 
    
    </div>
    

    

      
    </body>

    
  );
}

export default App;
