import { motion } from "framer-motion";

function About(){
    return (
        <body>


    <div className='estimate2'>
    <motion.div
  initial={{ opacity: 0 }}
  whileInView={{ opacity: 1 }}
  transition={{ duration: 1 }}
  viewport={{ once: true }}


>
      <section className='container3'>
    <header className='header2'>Local Mississauga Cleaning Services</header>
    <p>
    Welcome to Open Air Services, your trusted partner for professional cleaning services in Mississauga. With years of experience, we take pride in delivering exceptional cleaning solutions tailored to your needs.

    Our dedicated team is committed to providing the highest quality service, ensuring your home is not just cleaned, but revitalized. We understand the importance of a clean and healthy living space, and we guarantee some of the lowest rates in the industry without compromising on quality.

    Whether you need a one-time deep cleaning or regular maintenance, we've got you covered. Experience the difference with Open Air Services, where cleanliness meets affordability.

    Contact us today to schedule your cleaning service and discover the joy of coming home to a fresh, spotless environment.
  </p>

    </section>
    </motion.div>
    
    <div className="estimate2">
    
  
    <section className="container2">     
    <header className='header2'>hours of operation</header>
    <p>Sunday closed</p>
    <p>Monday 10am-7pm</p>
    <p>Tuesday 10am-7pm</p>
    <p>Wednesday 10am-7pm</p>
    <p>Thursday 10am-7pm</p>
    <p>Friday 10am-7pm</p>
    <p>Saturday closed</p>

  </section>

  </div>

  
    </div>
    
    <div className="estimate5">
    
    <section className="container2">     
    <header className='header2'>hours of operation</header>
    <p>Sunday 12pm-12am</p>
    <p>Monday 12pm-12am</p>
    <p>Tuesday 12pm-12am</p>
    <p>Wednesday 12pm-12am</p>
    <p>Thursday 12pm-12am</p>
    <p>Friday 12pm-12am</p>
    <p>Saturday 12pm-12am</p>

  </section>

  </div>
  </body>
    )
}

export default About;